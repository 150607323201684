<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card card-custom gutter-b example example-compact">
          <div class="card-body">
            <fieldset>
              <v-legend
                :title="'Leaves'"
                :description="'Here are all the employees at your company, including terminated ones.'"
              ></v-legend>
              <div class="row mb-5">
                <div class="col-lg-3">
                  <select
                    @change="fetchLeaves(leaveType)"
                    class="mb-3 mr-sm-3 mb-sm-0 custom-select"
                    v-model="leaveType"
                  >
                    <option value="all">Leave Type --- All</option>
                    <option
                      v-for="(leaveTypeItem, index) in getActiveLeaveTypes"
                      :key="index"
                      :value="leaveTypeItem.id"
                    >
                      {{ leaveTypeItem.name }}
                    </option>
                  </select>
                </div>

                <div class="col-lg-2">
                  <router-link
                    :to="{ name: 'leave.employee.request' }"
                    style="width: 100%"
                    class="btn mr-3 btn-primary"
                  >
                    <i class="fa fa-paper-plane"></i>
                    Request Leave
                  </router-link>
                </div>
              </div>

              <div class="v-data-table v-data-table--fixed-header theme--light">
                <div class="v-data-table__wrapper">
                  <table class="table table-hover">
                    <thead>
                      <tr style="background-color: #f2f4f5">
                        <th width="80px">Type</th>
                        <th>Applied date</th>
                    
                        <th>Requested dates</th>
                        <th> Day(s)</th>
                        <th>Is Paid</th>
                        <th>Status</th>
                        <th width="100px"></th>
                      </tr>
                      <tr v-if="loader">
                        <td colspan="12" class="text-center">
                          <data-loader
                            :type="'dark'"
                            :padding="10"
                          ></data-loader>
                        </td>
                      </tr>
                      <tr>
                        <td
                          colspan="12"
                          class="text-center"
                          v-if="getEmployeeLeaves.length < 1 && !loader"
                        >
                          <div class="p-6">
                            <span>No Leave Request Found</span>
                          </div>
                        </td>
                      </tr>
                    </thead>
                    <tbody v-if="getEmployeeLeaves.length > 0 && !loader">
                      <tr
                        style="line-height: 40px"
                        v-for="(leaveItem, index) in getEmployeeLeaves"
                        :key="index"
                      >
                        <td style="width: 20%">
                          <div class="leave-type">
                            <span
                              class="icon-circle"
                              :style="
                                'background-color:' +
                                leaveItem.leave_type.color_code
                              "
                            >
                              <i :class="leaveItem.leave_type.icon"></i
                            ></span>
                            <span>{{ leaveItem.leave_type.name }}</span>
                          </div>
                        </td>
                        <td style="width: 10%">
                          {{ leaveItem.applied_date }}
                        </td>

                   
                        <td style="width: 20%">
                          {{ leaveItem.requested_date }}
                        </td>
                        <td style="width: 10%">{{ leaveItem.total_day }}</td>
                        <td style="width: 10%">
                             <i class="fa fa-times text-danger" v-if="leaveItem.status=='1' && leaveItem.is_paid=='0'"></i>
                              <!-- <i class="fa fa-circle text-default" v-if="leaveItem.status!='1'"></i> -->
                               <i class="fa fa-check text-success" v-if="leaveItem.status=='1' && leaveItem.is_paid=='1'" ></i>
                           </td>
                        <td style="width: 10%">
                          <span
                            :class="leaveItem.status | leaveStatusClass"
                            class="badge"
                            >{{ leaveItem.status | leaveStatus }}</span
                          >
                        </td>
                        <td style="width: 10%">
                          <router-link
                            v-if="leaveItem.status < 1"
                            :to="{
                              name: 'leave.employee.request.edit',
                              params: { id: leaveItem.id },
                            }"
                            href="#"
                            class="btn btn-icon btn-light btn-sm ml-2 mr-2"
                            v-b-tooltip
                            title=""
                          >
                            <i class="fa fa-edit text-success text-sm"></i>
                          </router-link>
                          <!-- <router-link
                            :to="{
                              name: 'leave.employee.details',
                              params: { id: leaveItem.id },
                            }"
                            href="#"
                            class="btn btn-icon btn-light btn-sm ml-2 mr-2"
                            v-b-tooltip
                            title=""
                          >
                            <i class="fa fa-eye text-primary text-sm"></i>
                          </router-link> -->
                          <a
                            @click="setDetails(leaveItem)"
                            class="btn btn-icon btn-light btn-sm ml-2 mr-2"
                            v-b-tooltip
                            title="view details"
                          >
                            <i class="fa fa-eye text-primary text-sm"></i>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.leave-type {
  display: inline-flex;
  .icon-circle {
    width: 30px;
    height: 30px;
    text-align: center;
    border-radius: 50px;
    color: white;
    margin-right: 10px;
    margin-top: 2px;

    i {
      color: #fff;
      font-size: 14px;
    }
  }
}
</style>

<script>
import {
  SET_BREADCRUMB,
  SET_EMPLOYEE_LEAVE_DETAILS,
} from "@/core/services/store/mutations.type";
import Legend from "@/includes/content/widgets/Legend";
import {
  FETCH_EMPLOYEE_LEAVES,
  FETCH_ACTIVE_LEAVE_TYPE,
} from "@/core/services/store/actions.type";
import dataLoader from "@/includes/content/DataLoader";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      loader: false,
      leaveType: "all",
    };
  },
  components: {
    "data-loader": dataLoader,
    "v-legend": Legend,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Leaves" }]);
    this.$store.dispatch(FETCH_ACTIVE_LEAVE_TYPE);
    this.fetchLeaves(this.leaveType);
  },
  methods: {
    fetchLeaves(leaveTypeId) {
      this.loader = true;
      this.$store
        .dispatch(FETCH_EMPLOYEE_LEAVES, leaveTypeId)
        .then(() => {
          this.loader = false;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    setDetails(data) {
      this.$store.commit(SET_EMPLOYEE_LEAVE_DETAILS, data);
      this.$router.push({ name: "leave.employee.details", params: { id: data.id } });
   
    },
  },
  computed: {
    ...mapGetters(["getEmployeeLeaves", "getActiveLeaveTypes"]),
  },
  filters: {
   leaveStatus(status) {
      var str = "";
      switch (status) {
        case "0":
          str = "Pending";
          break;
        case "1":
          str = "Accepted";
          break;
        case "2":
          str = "Declined";
          break;
        case "3":
          str = "Canceled";
          break;
      }
      return str;
    },
    leaveStatusClass(status) {
      var class_ = "";
      switch (status) {
        case "0":
          class_ = "badge-info";
          break;
        case "1":
          class_ = "badge-success";
          break;
        case "2":
          class_ = "badge-danger";
          break;
        case "3":
          class_ = "badge-secondary";
          break;
      }
      return class_;
    },
  },
};
</script>
